'use client';

import Loading from '@/app/(dynamic)/loading';
import {
  BIBLE_BOOKS_BY_NAME,
  fetchCurrentNewWeek,
  fetchCurrentWeek,
  fetchNewWeeks,
  fromServerTime,
  getThisWeek,
  isError,
} from '@/lib/helpers';
import {
  LECTIONARY_TYPES_MAP,
  LECTIONARY_TYPES_TAB,
  LECTIONARY_TYPES_TABS,
  NewLectionaryWeeksByReading,
  NewWeek,
  ThisWeek,
  Week,
} from '@/types/sermons';
import moment from 'moment-timezone';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useNotifications } from '../providers/notification-provider';
import { useAppState } from '../providers/state-provider';
import FeaturedMedia from '../this-week/featured-media';
import FeaturedSermons from '../this-week/featured-sermons';
import TextAndTools from '../this-week/text-and-tools';
import FrontPageRss from './front-page-rss';
import LectionaryTable from './lectionary-table';
import LectionaryTypesTabs from './lectionary-types-tabs';
import LectionaryYearsTabs from './lectionary-years-tabs';
import ThisWeeksZoom from './this-weeks-zoom';

export default function ThisWeekPage({
  isThisWeek = false,
  currentWeek: parentCurrentWeek,
  thisWeek: parentThisWeek,
  lectionaryWeeks: parentLectionaryWeeks,
  currentWeeks: parentCurrentWeeks,
  paramToken,
  hideContent = false,
}: {
  isThisWeek?: boolean;
  paramToken?: string;
  currentWeek: Week;
  thisWeek: ThisWeek;
  lectionaryWeeks?: {
    a: NewWeek[];
    b: NewWeek[];
    c: NewWeek[];
  };
  currentWeeks?: NewLectionaryWeeksByReading;
  hideContent?: boolean;
}) {
  const {
    state: {
      searchParams,
      searchParamsReady,
      user,
      //token,
      isBrowser,
      isLoading,
      mySermons,
    },
    dispatch,
  } = useAppState();
  const addNotification = useNotifications();

  useEffect(() => {
    dispatch({
      type: 'mySermons',
      payload: { ...mySermons, isMySermons: false },
    });

    return () => setLoading(false);
  }, []);

  const weekParam = searchParams.get('week');
  const weekParamMoment =
    weekParam !== null && moment(weekParam).isValid()
      ? fromServerTime(weekParam)
      : null;
  const momentWeek =
    weekParam !== null && weekParamMoment?.isValid()
      ? weekParamMoment
      : fromServerTime();
  const nextSunday = fromServerTime().startOf('day').isoWeekday(7).hour(13);
  if (
    (weekParam === null || !weekParamMoment?.isValid()) &&
    momentWeek.isAfter(nextSunday)
  ) {
    momentWeek.add(1, 'week');
  }
  momentWeek.isoWeekday(7).startOf('day');
  const thisSunday = fromServerTime().isoWeekday(7).startOf('day');
  if (thisSunday.isAfter(nextSunday)) {
    thisSunday.add(1, 'week');
  }
  const isThisSunday = momentWeek.isSame(thisSunday);
  const weekString = momentWeek.format('YYYY-MM-DD');

  const [thisWeek, setThisWeek] = useState(parentThisWeek);
  const [currentWeek, setCurrentWeek] = useState(parentCurrentWeek);
  const [trendingSocial, setTrendingSocial] = useState(
    [...(parentThisWeek.trendingSocial || [])].reverse(),
  );

  const sundayDate = fromServerTime(thisWeek.sunday);

  const [loading, setLoading] = useState(
    isBrowser && weekParamMoment && !sundayDate.isSame(weekParamMoment),
  );

  useEffect(() => {
    if (trendingSocial?.[0]?.id !== thisWeek.trendingSocial?.[0]?.id) {
      setTrendingSocial([...(thisWeek.trendingSocial || [])].reverse());
    }
  }, [thisWeek]);

  useEffect(() => {
    if (
      !loading &&
      ((weekParamMoment?.isValid() && !sundayDate.isSame(weekParamMoment)) ||
        (user?.lectionaryCalendarFavorite &&
          user.lectionaryCalendarFavorite !== currentWeek.denomination) ||
        (!user?.lectionaryCalendarFavorite && currentWeek.denomination !== 'R'))
    ) {
      setLoading(true);
      (async () => {
        const thisWeek = await getThisWeek(weekString, paramToken);
        const currentWeek = await fetchCurrentWeek(weekString, paramToken);

        setThisWeek(thisWeek);
        setCurrentWeek(currentWeek);
        if (!weekParamMoment) {
          dispatch({ type: 'currentWeek', payload: currentWeek });
        }

        setLoading(false);
      })().catch(console.error);
    }
  }, [
    currentWeek.denomination,
    //dispatch,
    loading,
    sundayDate,
    paramToken,
    user?.lectionaryCalendarFavorite,
    weekParam,
    weekParamMoment,
    weekString,
  ]);

  const lectionary = searchParams.get(
    'lectionary',
  ) as LECTIONARY_TYPES_TAB | null;
  const tab = searchParams.get('tab');

  const lectionaryType: LECTIONARY_TYPES_TAB = LECTIONARY_TYPES_TABS.includes(
    lectionary!,
  )
    ? lectionary!
    : user?.lectionaryCalendarFavorite === 'C'
      ? 'Roman Catholic Lectionary'
      : 'Revised Common Lectionary';

  const [lectionaryWeeks, setLectionaryWeeks] = useState<
    | {
        a: NewWeek[];
        b: NewWeek[];
        c: NewWeek[];
      }
    | undefined
  >(parentLectionaryWeeks);
  const [currentWeeks, setCurrentWeeks] = useState<
    NewLectionaryWeeksByReading | undefined
  >(parentCurrentWeeks);

  useEffect(() => {
    if (isBrowser && document.location.hostname.endsWith('lectionary.com')) {
      (async () => {
        const lectionaryWeeks = await fetchNewWeeks(
          paramToken,
          LECTIONARY_TYPES_MAP[lectionaryType]
        );
        if (!('a' in lectionaryWeeks)) {
          console.log('Error loading lectionary weeks.');//, lectionaryWeeks);
          addNotification({
            type: 'error',
            message: 'Error loading lectionary weeks.',
          });
          return;
        }
        setLectionaryWeeks(lectionaryWeeks);

        const currentWeeks = await fetchCurrentNewWeek(
          undefined,
          LECTIONARY_TYPES_MAP[lectionaryType],
          paramToken
        );
        if (isError(currentWeeks)) {
          console.log('Error loading current weeks.');//, currentWeeks);
          addNotification({
            type: 'error',
            message: 'Error loading lectionary weeks.',
          });
          return;
        }
        setCurrentWeeks(currentWeeks);
      })().catch(console.error);
    }
  }, [paramToken, searchParamsReady, isBrowser]);

  const firstWeek =
    currentWeeks?.[
      Object.keys(currentWeeks)[0] as keyof NewLectionaryWeeksByReading
    ];
  return (
    <main className='relative'>
      {(isLoading || loading) && (
        <Loading className='absolute inset-0 z-10 bg-white' />
      )}
      {isBrowser &&
        document.location.hostname.endsWith('lectionary.com') &&
        !isThisWeek &&
        !loading &&
        lectionaryWeeks &&
        currentWeeks && (
          <>
            <LectionaryTypesTabs lectionary={lectionaryType} />
            <LectionaryYearsTabs
              year={
                ['A', 'B', 'C'].includes(tab!) ? tab! : firstWeek?.year || 'A'
              }
            />
            <LectionaryTable
              lectionaryWeeks={lectionaryWeeks}
              currentWeeks={currentWeeks}
            />
          </>
        )}
      {((isBrowser &&
        (!document.location.hostname.endsWith('lectionary.com') //||
          //isThisWeek
          ))
          ||
        !isBrowser) &&
        //!loading &&
        (
          <>
            <section>
              <div className='text-center text-lg italic'>
                Suggested Texts for  {sundayDate.format('dddd, MMMM Do')} -{' '}
                {currentWeek.lectionary}
              </div>
              <ul className='mb-6 block text-center'>
                {thisWeek.studyTools && thisWeek.studyTools.gs && (
                  <>
                    <li className='mr-2 inline-block cursor-pointer text-sm text-sermons-dark hover:underline'>
                      <Link
                        href={`search/scripture?pericope=${
                          thisWeek.studyTools.gs.bible.number
                        }&chapter=${
                          thisWeek.studyTools.gs.bible.chapter
                        }&book=${
                          BIBLE_BOOKS_BY_NAME[thisWeek.studyTools.gs.bible.book]
                        }&type=Sermon%20Prep`}
                      >
                        {currentWeek.gs}
                      </Link>
                    </li>
                    <li className='inline-block'> &middot; </li>
                  </>
                )}
                {thisWeek.studyTools && thisWeek.studyTools.ep && (
                  <>
                    <li className='mx-2 inline-block cursor-pointer text-sm text-sermons-dark hover:underline'>
                      <Link
                        href={`search/scripture?pericope=${
                          thisWeek.studyTools.ep.bible.number
                        }&chapter=${
                          thisWeek.studyTools.ep.bible.chapter
                        }&book=${
                          BIBLE_BOOKS_BY_NAME[thisWeek.studyTools.ep.bible.book]
                        }&type=Sermon%20Prep`}
                      >
                        {currentWeek.ep}
                      </Link>
                    </li>
                    <li className='inline-block'> &middot; </li>
                  </>
                )}
                {thisWeek.studyTools && thisWeek.studyTools.ot && (
                  <>
                    <li className='mx-2 inline-block cursor-pointer text-sm text-sermons-dark hover:underline'>
                      <Link
                        href={`search/scripture?pericope=${
                          thisWeek.studyTools.ot.bible.number
                        }&chapter=${
                          thisWeek.studyTools.ot.bible.chapter
                        }&book=${
                          BIBLE_BOOKS_BY_NAME[thisWeek.studyTools.ot.bible.book]
                        }&type=Sermon%20Prep`}
                      >
                        {currentWeek.ot}
                      </Link>
                    </li>
                    <li className='inline-block'> &middot; </li>
                  </>
                )}
                {thisWeek.studyTools && thisWeek.studyTools.ps && (
                  <li className='ml-2 inline-block cursor-pointer text-sm text-sermons-dark hover:underline'>
                    <Link
                      href={`search/scripture?pericope=${
                        thisWeek.studyTools.ps.bible.number
                      }&chapter=${thisWeek.studyTools.ps.bible.chapter}&book=${
                        BIBLE_BOOKS_BY_NAME[thisWeek.studyTools.ps.bible.book]
                      }&type=Sermon%20Prep`}
                    >
                      {currentWeek.ps}
                    </Link>
                  </li>
                )}
              </ul>
              <hr className='my-2' />
            </section>
            {!hideContent && (
              <>
              <section>
                <FeaturedMedia media={thisWeek.media} />
                <hr className='my-4' />
              </section>
              <section>
                <div className='grid grid-cols-1 lg:grid-cols-[repeat(24,minmax(0,1fr))]'>
                  <FeaturedSermons sermons={thisWeek.sermons} />
                  <ThisWeeksZoom />
                  <div className='col-span-9 mt-8 border-l-0 border-gray-200 text-right lg:mt-0 lg:border-l lg:pl-4'>
                    <div className='font-bold'>
                      This Week&apos;s Illustrations
                    </div>
                    <ul className='mb-8 grid grid-cols-1 gap-8'>
                      <li>
                        <Link
                          className='text-sm italic hover:underline'
                          href={`/illustrations${
                            isThisSunday ? '' : `?week=${weekString}`
                          }`}
                        >
                          Illustrations for {sundayDate.format('MMMM D, YYYY')}{' '}
                          (Year {currentWeek.cycle} - {currentWeek.lectionary})
                        </Link>
                        <div className='font-serif text-xs italic text-gray-400'>
                          by Our Staff - {thisWeek.scripture}
                        </div>
                      </li>
                    </ul>
                    <div className='font-bold'>Sermon Series</div>
                    <ul>
                      {thisWeek.seriesCategories && thisWeek.seriesCategories.map((seriesCategory) => (
                        <li key={seriesCategory.id}>
                          <div className='cursor-pointer text-sm italic hover:underline'>
                            <Link
                              href={`sermon-series?category=${seriesCategory.id}`}
                            >
                              {seriesCategory.title}
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <hr className='my-4' />
              </section>
              <section>
                <div className='mb-4 font-bold'>
                  Trending Social &middot;{' '}
                  <Link
                    className='italic text-sermons-dark hover:underline'
                    href='/trending-social'
                  >
                    see all
                  </Link>
                </div>
                <div className='mb-8 grid grid-cols-1 text-sm lg:grid-cols-2'>
                  <ul className='ml-4 list-disc'>
                    {trendingSocial.slice(0, 3).map((trendingSocial) => (
                      <li key={trendingSocial.id}>
                        <a
                          className='cursor-pointer italic hover:underline'
                          href={trendingSocial.url}
                          rel='noopener noreferrer nofollow'
                          target='_blank'
                        >
                          {trendingSocial.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <ul className='ml-4 list-disc'>
                    {trendingSocial.slice(3, 6).map((trendingSocial) => (
                      <li key={trendingSocial.id}>
                        <a
                          className='cursor-pointer italic hover:underline'
                          href={trendingSocial.url}
                          rel='noopener noreferrer nofollow'
                          target='_blank'
                        >
                          {trendingSocial.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <hr className='my-4' />
              </section>
              <TextAndTools thisWeek={thisWeek} />
              <section>
                <div className='grid grid-cols-1 lg:grid-cols-2'>
                  {thisWeek.rss && (
                    <>
                      <FrontPageRss rssFeed={thisWeek.rss.christianpost} />
                      {/* <FrontPageRss rssFeed={thisWeek.rss.christianitytoday} /> */}
                      <FrontPageRss rssFeed={thisWeek.rss.flipboard} />
                      <FrontPageRss rssFeed={thisWeek.rss.realclearreligion} />
                    </>)}
                </div>
              </section>
            </>
          )}
          </>
        )}
    </main>
  );
}
