'use client';

import { Content } from '@/types/sermons';
import Link from 'next/link';
import slugify from 'slugify';

export default function FeaturedSermons({ sermons }: {
  sermons: Content[];
}) {
  return (
    <div className='col-span-9'>
      <div className='font-bold'>
        This Week&apos;s Sermons &middot; <Link className='italic text-sermons-dark hover:underline' href='/search/keyword?term=&type=Sermon%20Prep'>see all</Link>
      </div>
      <ul className='grid grid-cols-1 gap-4'>
        {sermons && sermons.map(sermon => (
          <li key={sermon.id}>
            <div>
              <Link className='text-sm italic hover:underline' href={`/sermon/${slugify(sermon.contentTitle).toLowerCase()}/${sermon.id}`}>
                {sermon.contentTitle}
              </Link>
              {(sermon.authors?.length || 0) > 0 &&
                <span className='font-serif text-xs italic text-gray-400'> • {sermon.authors?.map((author, index) => (
                  <span key={`${sermon.id}-${author.id}`}>{index > 0 && <span>, </span>}{author.firstname} {author.lastname}</span>
                ))}</span>
              }
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
